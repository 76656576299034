import moment from 'moment';
import 'regenerator-runtime/runtime'

jQuery( document ).ready(function($) {

    // Mobile menu toggle
    $('.menu-toggle').click(function(){
        $('.main-menu, header .social').slideToggle();
        $(this).toggleClass('active');
    });

    // Sticker Header
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
    
        if (scroll > 200) {
            $(".sticky-header").addClass("active");
        } else {
            $(".sticky-header").removeClass("active");
        }
    });

    // sliders
    $('.music-slider').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });

      $('.video-slider').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
        responsive: [
          {
            breakpoint: 840,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });

      $('.blog-grid').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 4000,
            settings: 'unslick'
          }
        ]
      });

      $('.photos-slider').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
        centerMode: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 840,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });


      setTimeout(() => {
        $('.all-shows').slick({
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
          dots: true,
          responsive: [
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 4000,
              settings: 'unslick'
            }
          ]
        });
        }, 6000);

        setTimeout(function(){
          $('.artists-grid').addClass('active')
          $('.artists-grid').slick({
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
            prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
            rows: 2,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  infinite: false,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-circle-right"></i></button>',
                  prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-circle-left"></i></button>',
                  rows: 2,
                  dots: true,
                  arrows: false,
                  autoplay: true,
                  autoplaySpeed: 2000,
                }
              },
              {
                breakpoint: 4000,
                settings: 'unslick'
              }
            ]
          });
        }, 10);

    //   team popup
    $('.team-btn').click(function(){
        $(this).siblings('.team-popup').addClass('active');
    });
    $('.close').click(function(){
        $(this).closest('.team-popup').removeClass('active')
    });

    //   literary popup
    $('.literary-btn').click(function(){
      $(this).siblings('.literary-popup').addClass('active');
  });
  $('.close').click(function(){
      $(this).closest('.literary-popup').removeClass('active')
  });

  // Artist page Shows view more
  if (document.querySelector('body').classList.contains('single-artist')){
    setTimeout(() => {
      const howManyShows = document.querySelector('.shows-feed').childElementCount
      if (howManyShows > 4){
        document.querySelector('.show-more-shows.mobile').classList.add('active')
      }
      if (howManyShows > 10){
        document.querySelector('.show-more-shows.desktop').classList.add('active')
      }
    }, 1000);
    $('.show-more-shows').click(function(){
        $('.shows-feed').toggleClass('show-all')
        $(this).toggleClass('active')
        $('.show-less-shows').toggleClass('active')
    });
    $('.show-less-shows').click(function(){
      $('.shows-feed').toggleClass('show-all')
      $(this).toggleClass('active')
      $('.show-more-shows').toggleClass('active')
    });
  } 
  
  // SHOWS
  const allArtists = php_data.artists;
  allArtists.forEach(artistsShows);
  function artistsShows(item) {
    if (item) {
      const artistName = item.toLowerCase().replace(/\s+/g, '-')
      const artistNameUrl = item.toLowerCase().replace(/\s+/g, '%20')
      // Tour
      $.ajax( {
        url: `https://rest.bandsintown.com/artists/${artistNameUrl}/events?app_id=45PRESS_${artistName}`,
        method: 'GET',
        dataType: 'json',
        xhrFields: {
          withCredentials: false
        },
        error: () => {
        },
        success: data => {
          const events = $( `#${artistName}-shows` );
          let html = '';
          if ( data.length ) {
            for ( let event of data ) {
              const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
              html += '<div class="show" data-bit-id="' + event.id + '">';
              html += '<p class="date">' + moment( event.datetime ).format( 'MMM DD' ) + '</p>';
              html += '<p class="location">' + event.venue.name + '</p>';
              html += '<p class="city">' + event_location + '</p>';
              html += '<a href="' + event.url + '" target="_blank" class="btn">Tickets</a>';
              html += '</div>';
            }
            events.html( html );
          } else {
            events.html( 'No upcoming events.' );
          }
        }
      });
    };
  }
  
  const isAllShows = document.querySelector('body').classList.contains('page-template-shows-page');

  
  // Homepage and all shows!
  async function buildDates() {
    let all_events = {};
    for (let artist of php_data.artists) {
      if (artist !== null) {
        let events = await getEvents(artist);
        
        for (let event of events) {
          const date = event.datetime.split("T")[0];
          if (!(date in all_events)) {
            all_events[date] = [];
          }
          all_events[date].push(event);
        }
      }
    }
    all_events = Object.keys(all_events).sort().reduce((a, c) => (a[c] = all_events[c], a), {})
    
    if (isAllShows){
      showsFilter.classList.remove('hide')
    }
    
    const  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const allEvents = document.querySelector( '#all-shows' );
    const allEventsFull = document.querySelector( '#all-shows-full' );
    
    if (allEvents){
      let i = 0
      for (const property in all_events) {
        let day = all_events[property]
        let date = property.split('-')
        let dateDay = date[2]
        let dateMonthNumber = date[1]
        let dateMonthName = months[dateMonthNumber - 1];
  
        allEvents.innerHTML += `<div class="date date-${i}"><div class="date-heading"><p class="month">${dateMonthName}</p><p class="day">${dateDay}</p></div></div>`
        var showsContainer = document.createElement(`DIV`);
        showsContainer.classList.add(`shows-shows-${i}`)
        showsContainer.classList.add(`shows-shows`)
        document.querySelector(`.date-${i}`).appendChild(showsContainer);

        for (const property in day){
          let event = day[property]
          const eventArtist = event.lineup[0]
          const eventVenueName = event.venue.name
          const eventVenueLocation = event.venue.location
          const eventUrl = event.url
          const innerElement = document.querySelector(`.shows-shows-${i}`)
  
          innerElement.innerHTML += `<a href="${eventUrl}" class="shows-show"><p class="artist">${eventArtist}</p><p class="location">${eventVenueName}, ${eventVenueLocation}</p><span>Tickets</span></a>`
        }
  
        if (i === 3){break}
        i++
        allEvents.classList.add('loaded')
      }
    }

    if(allEventsFull){
      let h = 0
      for (const propertyB in all_events) {
        let day = all_events[propertyB]
        let date = propertyB.split('-')
        let dateDay = date[2]
        let dateMonthNumber = date[1]
        let dateMonthName = months[dateMonthNumber - 1];
  
        allEventsFull.innerHTML += `<div class="date date-${h}"><div class="date-heading"><p class="month">${dateMonthName}</p><p class="day">${dateDay}</p></div></div>`
        var showsContainer = document.createElement(`DIV`);
        showsContainer.classList.add(`shows-shows-${h}`)
        showsContainer.classList.add(`shows-shows`)
        document.querySelector(`.date-${h}`).appendChild(showsContainer);
        
        for (const propertyB in day){
          let event = day[propertyB]
          const eventArtist = event.lineup[0]
          const eventVenueName = event.venue.name
          const eventVenueLocation = event.venue.location
          const eventUrl = event.url
          const innerElement = document.querySelector(`.shows-shows-${h}`)
  
          innerElement.innerHTML += `<a href="${eventUrl}" class="shows-show"><p class="artist">${eventArtist}</p><p class="location">${eventVenueName}, ${eventVenueLocation}</p><span>Tickets</span></a>`
        }
        h++
      }
      allEventsFull.classList.add('loaded')

    }
  }
  
  buildDates();
  
  async function getEvents(artist) {
    let result;
  
    try {
      result = await $.ajax({
        url: 'https://rest.bandsintown.com/artists/' + artist + '/events?app_id=45PRESS_' + artist,
        method: 'GET',
        dataType: 'json',
        xhrFields: {
          withCredentials: false
        },
      });
  
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  // Internal artists pages
  $('.go-internal, .close-popup').click(() => {
    $('.internal-popup').fadeToggle();
  })

  
  const assetsPass = assets_pass.pass

  $( ".internal-form" ).submit(function( e ) {
    e.preventDefault();
    const pass = e.currentTarget.querySelector('#internal-password').value
    if (pass === assetsPass){
      $('#internal-password').removeClass('error')
      e.currentTarget.querySelector('#internal-password').value = ""
      $('.internal-popup').fadeToggle();
      $('.public').hide();
      $('.internal').show();
      document.querySelector('#main').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    } else {
      $('#internal-password').addClass('error')
    }
  });

  $('.go-public').click(() => {
    $('.public').show();
    $('.internal').hide();
    document.querySelector('#main').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  })


  // SHOWS FILTER

  function resetFilter(){
    const resetDates = document.querySelectorAll('.date')
    const resetArtist = document.querySelectorAll('.artist')
    const resetShow = document.querySelectorAll('.shows-show')

    resetDates.forEach((e) => {
        e.classList.remove('match', 'hide')
    })
    resetArtist.forEach((e) => {
        e.classList.remove('match', 'hide')
    })
    resetShow.forEach((e) => {
        e.classList.remove('match', 'hide')
    })
}

const showsFilter = document.querySelector('.shows-filter')
setTimeout(function(){
    const allDates = document.querySelectorAll('.date')
    if (isAllShows){
      showsFilter.addEventListener('submit', (e) => {
        const filterThis = e.currentTarget.querySelector('.artist-name').value.toLowerCase().replace(/\s+/g, '-')
  
        e.preventDefault()
        resetFilter()
    
        if (filterThis !== ''){
          allDates.forEach((e) => {
            e.classList.add('hide')
            const artists = e.querySelectorAll('.artist')
            artists.forEach((f) => {
              if (f.innerHTML.toLowerCase().replace(/\s+/g, '-') === filterThis){
                f.classList.add('match')
                f.parentElement.classList.add('match')
                f.parentElement.parentElement.parentElement.classList.add('match')
              } else{
                f.parentElement.classList.add('hide')
                f.classList.add('hide')
              }
            })
          } )
        } else {
          resetFilter()
        }
      })
    }
  }, 5000);

  if (document.querySelector('body').classList.contains('blog')){
    // news filter
    const newsFilter = document.querySelector('.news-filter')
  
    const newsFilterHandler = (e) => {
      e.preventDefault()
  
      const targetNewsArtis = document.querySelector('.news-filter-term').value
      const allNews = document.querySelectorAll('.blog-item')
  
  
  
      allNews.forEach( (news) => {
        const newsArtist = news.getAttribute('artist')
  
        news.classList.remove('active')
        if(targetNewsArtis === 'all'){
          news.classList.add('active')
        } else{
          if (newsArtist === targetNewsArtis){
            news.classList.add('active')
          }
        }
      })
    }
  
    newsFilter.addEventListener('submit', newsFilterHandler)
  }

  var homeArtists = $(".random .artist-item");
  for(var i = 0; i < homeArtists.length; i++){
      var target = Math.floor(Math.random() * homeArtists.length -1) + 1;
      var target2 = Math.floor(Math.random() * homeArtists.length -1) +1;
      homeArtists.eq(target).before(homeArtists.eq(target2));
  }

});
